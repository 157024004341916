import React from 'react';

function App() {
  return (
    <div>
      <h1>Hello, LocusTrail!</h1>
      <p>This is a basic React application with UTM parameter persistence.</p>
      <a href="/another-page">Go to another page</a>
    </div>
  );
}

export default App;
